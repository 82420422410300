.header {
  position: fixed;
  top: 0;
  z-index: var(--app-z-index);
  display: flex;
  padding-right: var(--padding-medium);
  padding-left: var(--padding-medium);
  width: 100%;
  height: var(--site-header-height);
  background-color: var(--content-area-background-color-hex);
  color: var(--secondary-color-5);

  &.isLanding {
    background-color: transparent;
    color: var(--secondary-color-5);

    &.colorsInverted {
      color: white;
    }
  }

  &.positionOne {
    opacity: 0;
    top: -70px;
  }

  &.positionTwo {
    transition: top 0.4s ease-in;
    background-color: var(--secondary-color-5);
    border-bottom: 2px solid var(--ink-dark);
    color: white;
    opacity: 1;
  }

  &.positionThree {
    top: 0;
  }

  &.minimalHeader {
    background-color: var(--secondary-color-5);
  }

  @media (--min-width-large) {
    display: none;

    &.mobile:not(.isLanding) {
      display: flex;
    }

    &.minimalHeader {
      background-color: var(--secondary-color-5);
      display: flex;
    }
  }
}

.headerWrapper {
  position: relative;
  z-index: 1401;
  display: flex;
  width: 100%;
  height: 100%;
}

.tuneInLogoContainer {
  display: flex;
  flex: 1;
  justify-content: center;

  @media (--min-width-xsmall) {
    justify-content: flex-start;
  }
}

.searchToggleContainer {
  display: block;
  padding-top: 20px;
  cursor: pointer;

  @media (--min-width-large) {
    padding-right: 24px;
  }
}

.sidebarToggleIconContainer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: var(--base-transition);
  cursor: pointer;
  font-size: 22px;

  @media (--min-width-xsmall) {
    margin-right: 24px;
  }
}
