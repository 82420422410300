#onetrust-consent-sdk #ot-sdk-btn-floating {
  .ot-floating-button__front {
    background-color: var(--tan);

    &.custom-persistent-icon {
      border-radius: 12px;
    }
  }

  .ot-floating-button__back {
    background-color: var(--tan);

    &.custom-persistent-icon {
      border-radius: 12px;
    }
  }

  &.ot-floating-button {
    animation: floatingBtnIntro 800ms ease 0ms 1 forwards;

    &::before {
      transform: translate(-0.5em, -50%) rotate(180deg);
      right: unset;
      left: calc(0em - 5px);
    }

    &::after {
      left: unset;
      right: calc(100% + 26px);
    }

    &:hover {
      &::before {
        transform: translate(-0.5em, -50%) scale(1) rotate(180deg);
      }

      &::after {
        transform: translate(14px, -50%) scale(1);
      }
    }
  }
}

body.playerVisible {
  #onetrust-consent-sdk #ot-sdk-btn-floating.ot-floating-button {
    bottom: calc(var(--player-height) + 10px);
  }
}

@keyframes floatingBtnIntro {
  from {
    opacity: 0;
    right: -75px;
  }

  to {
    opacity: 1;
    right: 8px;
  }
}
