.discordSplash {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  background-color: var(--dark-grey);
  transform: translate(-50%, -50%);
  z-index: var(--full-screen-splash-z-index);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loading {
    width: 150px;
  }

  .tiBadge {
    position: absolute;
    top: 16px;
    left: 20px;

    :global(html.discord.mobile) & {
      top: 8px;

      @media (--min-width-discord-picture-in-picture-small) {
        top: var(--discord-browsies-header-top-padding);
      }
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    overflow: auto;

    @media (--min-width-small) {
      width: 60vw;
    }

    img {
      max-width: 70%;
      max-height: 70%;
      margin-bottom: 4px;

      @media (--min-width-discord-picture-in-picture-small) {
        margin-bottom: 16px;
      }

      @media (orientation: landscape) {
        max-width: 50%;
        max-height: 50%;
        margin-bottom: 4px;
      }

      @media (--min-width-small) {
        max-width: 60%;
        max-height: 60%;
        margin-bottom: 16px;
      }
    }

    span {
      color: var(--text-color);
      font-size: 10px;
      text-align: center;

      @media (--min-width-discord-picture-in-picture-small) {
        font-size: var(--font-size-2);
      }

      @media (--min-width-small) {
        font-size: var(--font-size-3);
      }
    }
  }

  .spinner {
    width: 32px;
    height: 32px;
    border: 2px solid var(--anti-flash-white);
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: spin 1s linear infinite;
    margin-top: 60px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
