@mixin errorImage() {
  width: 100%;
  max-width: 415px;
  height: 100%;
  position: relative;
  margin-top: 51px;
}

.errorTextTitle {
  font-weight: bold;
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  font-stretch: normal;
  margin-bottom: 16px;
  color: var(--text-color);
}

.errorText {
  width: 100%;
  font-size: var(--font-size-4);
  line-height: 28px;
  text-align: center;
  font-stretch: normal;
  color: var(--text-color);

  @media (--min-width-xxlarge) {
    font-size: var(--font-size-5);
  }
}

.searchBarContainer {
  margin-top: 43px !important;

  @media (--min-width-medium) {
    margin-top: 96.5px !important;
  }

  :global(html.discord) & {
    margin-top: 0 !important;
  }
}

.errorTextAndVideoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.withoutSearch {
  margin-top: 42px !important;
}

.errorTextContainer {
  position: relative;
  max-width: 700px;
  width: 100%;
  margin-top: 32px;
  z-index: 0;
}

.underlineLink {
  composes: link from '../../styles/common.module.scss';
  color: var(--secondary-color-5);
  cursor: pointer;

  span {
    position: relative;
    display: inline-block;
    white-space: nowrap;

    i {
      position: absolute;
      bottom: 0;
      opacity: 1;
      left: 0;
      width: 100%;
      height: 2px;
      border-radius: 0.5px;
      color: var(--secondary-color-5);
      background-color: var(--secondary-color-5);
      transition: var(--base-transition);
    }

    &:active,
    &:hover,
    &:focus {
      color: var(--secondary-color-5);

      i {
        color: var(--secondary-color-5);
        bottom: -2px;
      }
    }
  }
}

@keyframes animateLineLight {
  0% {
    border-width: 8px;
    opacity: 0.5;
  }

  33.3% {
    border-width: 8px;
    opacity: 0.5;
  }

  35.3% {
    border-width: 18px;
    opacity: 1;
  }

  37.3% {
    border-width: 8px;
    opacity: 0.4;
  }

  39.3% {
    border-width: 6px;
    opacity: 1;
  }

  41.3% {
    border-width: 4px;
    opacity: 0.6;
  }

  43.3% {
    border-width: 8px;
    opacity: 0.4;
  }

  45.3% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes animateTextLight {
  0% {
    opacity: 0.3;
  }

  33.3% {
    filter: blur(5px);
    opacity: 0.3;
  }

  35.3% {
    opacity: 1;
  }

  37.3% {
    opacity: 0.03;
  }

  39.3% {
    opacity: 0.6;
  }

  41.3% {
    opacity: 0.3;
  }

  43.3% {
    opacity: 0.4;
  }

  45.3% {
    filter: blur(0);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.errorOnAirImage {
  @include errorImage;

  @media (--min-width-xxlarge) {
    margin-top: 97px;
  }
}

.image1 {
  z-index: 1;
  width: 100%;
}

.image2 {
  z-index: 3;
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  filter: blur(0);
  opacity: 0.5;
  border-width: 8px;
  border-radius: 3px;
  border-color: rgb(17 215 204 / 100%);
  background-color: rgb(123 123 123 / 0%);
  transition-timing-function: cubic-bezier(0.34, 0, 0.1, 1);
  animation: animateLineLight 5s infinite;
}

.image3 {
  position: absolute;
  z-index: 2;
  opacity: 0.3;
  border: 0;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  filter: blur(0);
  transition-timing-function: cubic-bezier(0.34, 0, 0.1, 1);
  animation: animateTextLight 5s infinite;
}
