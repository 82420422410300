.searchBarContainer {
  display: block;
  margin-top: var(--search-bar-container-margin-top);
  max-width: 100%;
  padding-bottom: 6px;
}

.searchBarContainer > div > div {
  background-color: transparent !important;
}

.searchBarContainer input::placeholder {
  /* Chrome/Opera/Safari */
  font-size: var(--font-size-7);
  font-weight: 600;
  line-height: 1.14;
  text-align: left;
  color: var(--secondary-color-5);
}

input:focus::placeholder {
  opacity: 0;
}

.searchIconContainer {
  position: absolute;
  width: var(--medium-icon-size);
  height: var(--medium-icon-size);
  top: 10px;
}

.containerItems {
  margin-top: 34px;
}

.topBannerContainer {
  margin-bottom: 45px;
}

.searchBarWrapperClass {
  align-self: center;
  border: 1px solid var(--input-border-color);
  border-radius: 42px;
  background-color: var(--input-bg-color);
  padding-left: 21px;

  :global(html.discord) & {
    border: 1px solid rgb(255 255 255 / 20%);

    &:focus-within {
      border: 1px solid var(--input-border-color);
    }
  }
}
