.dialogContent {
  line-height: 1.5;
  text-align: center;
  margin-bottom: 16px;
  color: var(--secondary-color-5);
}

.heading {
  line-height: 1.5;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
  color: black;
}

.button {
  width: 100%;
  border-radius: 8px;
  height: 40px;
}
