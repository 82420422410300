.wrapper {
  width: 100%;
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-size: var(--font-size-2);
  left: 10px;
  z-index: 1;
  cursor: pointer;

  &:hover {
    color: #fff;
  }

  &:focus {
    color: #000;
  }
}

.field {
  background-color: #4e4e4e;

  &:hover {
    background-color: #797979;
  }
}
