.button {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px;
  background-color: transparent;
  border: none;
  z-index: 1;
}

.closeButton,
.closeButtonDark {
  font-size: var(--font-size-3);
  color: black;
}

.closeButtonSvg,
.closeButtonSvgDark {
  display: block;
  background: transparent url('/assets/img/shared/close-button.svg') no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.closeButtonDark {
  color: white;
}

.closeButtonSvgDark {
  background-image: url('/assets/img/shared/close-button-white.svg');
}
