.disabledState {
  &[disabled] {
    border: none;
    background-color: var(--51st-shade-of-grey);
    cursor: not-allowed;

    span,
    i {
      color: white;
    }

    /*
      Needed for keyboard/tab focusing. The mouse leave handler in the PillButton class takes
      care of blurring.
    */
    &:hover,
    &:focus,
    &:active {
      border: none;
      background-color: var(--51st-shade-of-grey);

      span,
      i {
        color: white;
      }
    }
  }
}

.solidButton {
  composes: link from '../../../styles/common.module.scss';
  composes: disabledState;
  display: block;
  padding: 5px;
  width: 100%;
  height: var(--regular-button-height);
  border: none;
  border-radius: 2px;
  background-color: var(--secondary-color-5);
  appearance: none;
  color: white;
  text-align: center;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: var(--font-size-3);
    transition: var(--base-transition);

    i {
      margin-right: 10px;
      color: white;
      font-size: var(--font-size-2);
    }
  }

  /*
    Needed for keyboard/tab focusing. The mouse leave handler in the PillButton class takes
    care of blurring.
  */
  &:hover,
  &:focus,
  &:active {
    background-color: var(--knighthood-grey);

    span,
    i {
      color: white;
    }
  }

  &[data-small] {
    height: var(--small-button-height);

    span {
      font-size: var(--font-size-2);
    }
  }
}

.outlinedButton {
  composes: solidButton;
  border: 2px solid var(--secondary-color-5);
  background-color: transparent;

  span {
    color: var(--secondary-color-5);

    i {
      color: var(--secondary-color-5);
    }
  }

  /*
    Needed for keyboard/tab focusing. The mouse leave handler in the PillButton class takes
    care of blurring.
  */
  &:hover,
  &:focus {
    border-color: var(--knighthood-grey);
    background-color: var(--knighthood-grey);

    span,
    i {
      color: white;
    }
  }

  &:active {
    border: none;
    background-color: var(--secondary-color-1);

    span {
      color: white;
    }
  }

  &[disabled] {
    &:hover {
      border: none;
      color: var(--secondary-color-1);
    }
  }
}

.coral {
  background-color: var(--tunein-coral);
  border: none;
  border-radius: 3px;

  span {
    color: var(--secondary-color-5);
    font-size: var(--font-size-3);
    line-height: 20px;
  }

  &:hover,
  &:focus,
  &:active {
    border: none;
    background-color: var(--tunein-coral-hover);

    span {
      color: var(--secondary-color-5);
    }
  }

  &[disabled] {
    background-color: var(--tunein-coral-hover);

    span {
      color: var(--secondary-color-5);
    }

    &:hover,
    &:focus,
    &:active {
      background-color: var(--tunein-coral-hover);

      span {
        color: var(--secondary-color-5);
      }
    }
  }
}

.premiumGold {
  background-color: var(--premium-gold-2);
  border: none;
  color: white;
  border-radius: 8px;

  span {
    color: white;
    font-size: var(--font-size-3);
    line-height: 20px;
  }

  &:hover,
  &:focus {
    background-color: var(--premium-gold-2-hover);
  }

  &:active {
    background-color: var(--premium-gold-2);
  }

  &[disabled] {
    background-color: var(--disabled-grey);

    > span {
      color: var(--grey-6);
    }

    &:hover,
    &:focus,
    &:active {
      background-color: var(--disabled-grey);
    }
  }
}

.sky {
  color: var(--secondary-color-5);
  background-color: var(--sky);

  > span {
    color: var(--secondary-color-5);
  }

  &:hover {
    background-color: var(--sky-hover);
  }

  &:active {
    background-color: var(--sky-active);
  }

  &[disabled] {
    background-color: var(--ink-11);

    > span {
      color: var(--ink-extra-light);
    }

    &:hover,
    &:focus,
    &:active {
      background-color: var(--ink-11);

      > span {
        color: var(--ink-extra-light);
      }
    }
  }
}

.ink {
  background-color: var(--secondary-color-5);
  border: none;
  border-radius: 33px;
  user-select: none;

  span {
    font-weight: bold;
    color: var(--button-label-color);
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    letter-spacing: var(--letter-spacing-loose);
    text-transform: uppercase;
  }

  &:hover,
  &:focus {
    background-color: var(--knighthood-grey);
  }

  &:active {
    background-color: var(--cloud-grey);
  }

  &[disabled] {
    background-color: var(--51st-shade-of-grey);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--51st-shade-of-grey);
    }
  }
}

.naked {
  background-color: transparent;
  border: none;
  user-select: none;

  span {
    font-weight: bold;
    color: var(--secondary-color-5);
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    letter-spacing: var(--letter-spacing-loose);
    text-transform: uppercase;
  }

  &:hover,
  &:focus {
    background-color: transparent;

    span {
      color: var(--knighthood-grey);
    }
  }

  &:active {
    background-color: transparent;

    span {
      color: var(--cloud-grey);
    }
  }

  &[disabled] {
    background-color: transparent;

    span {
      color: var(--51st-shade-of-grey);
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;

      span {
        color: var(--51st-shade-of-grey);
      }
    }
  }
}

.extraRounded {
  border-radius: 8px;
}

.largeText {
  font-size: var(--font-size-4);
}
