.container {
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
  z-index: var(--top-z-index);
  background-color: rgb(0 0 0 / 20%);
  padding: 4px;
  border-radius: 4px;
}

/* multiply the width of the image times 121 to get the final position = 19360 */
@keyframes play {
  100% {
    background-position: -19360px;
  }
}

@keyframes playSmall {
  100% {
    background-position: -9680px;
  }
}

.loader {
  width: 80px;
  height: 80px;
  background: url('/assets/img/loader-small.png') 0 0;
  animation: playSmall 3s steps(121) infinite;

  @media (--min-width-small) {
    width: 160px;
    height: 160px;
    background: url('/assets/img/loader.png') 0 0;
    animation: play 3s steps(121) infinite;
  }
}
