.button {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  padding: 0.8rem 1rem;
  margin: 0.8rem;
  background-color: #ccc;
  cursor: pointer;
  user-select: none;
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 99999;
  padding: 0.8rem;
  box-sizing: border-box;
  background-color: #000;
  color: #fff;
  opacity: 0.7;
  user-select: none;
  overflow-y: scroll;

  &.visible {
    display: block;
  }
}
