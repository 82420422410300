.dialogBody {
  height: 100%;
  border: none;
}

.bodyFullScreen {
  /* material-ui puts a max-height on dialog content. This overrides it */

  /* so the dialog can be full screen. */
  height: 100% !important;
  padding: 0 !important;
}

.content {
  /* if a user's browser window happens to be below 550px height, allow dialog to render at the top of the screen */
  @media (max-height: 550px) {
    transform: none !important;
  }

  > div {
    height: 100%;
  }
}

.contentFullScreen {
  /*
    material-ui hack so Dialog covers entire screen
  */
  position: absolute !important;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  transform: none !important;
  max-width: none !important;
  border-radius: 0;
}
