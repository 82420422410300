.dialogBody {
  padding: 30px;
  background-color: var(--secondary-color-5);
  color: white;
}

.dialogWrapper {
  font-size: var(--font-size-5);
  padding: 50px 20px 15px;
  color: white;

  &.compact {
    padding: 5px 10px 15px;
  }

  .title {
    font-size: 51px;
    color: var(--sky);
    line-height: 1;
    font-weight: bold;
  }

  .subtitle {
    font-size: 27px;

    @media (--min-width-xsmall) {
      font-size: 34px;
    }

    &.bold {
      font-weight: var(--font-weight-bold);
    }
  }

  .content {
    line-height: 1.4;
    margin: 20px 0 30px;

    &.compact {
      margin: 10px 20px 35px 0;
    }
  }

  .button {
    height: 48px;
    
    span {
      color: var(--secondary-color-5);
      font-size: var(--font-size-5);
    }
  }
}

/* in app messaging dialog */
.iconBlock {
  background-size: contain;
  width: 70px;
  height: 70px;
  position: relative;
  right: 10px;
}

.backBufferIcon {
  background: transparent url('/assets/img/shared/back-buffer-icon.svg')
    no-repeat;
}

.buttonWrapper {
  width: 48%;
  float: left;
  margin-right: 4%;

  .button {
    span {
      font-weight: var(--font-weight-bold);
      font-size: 17px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.itemRow {
  margin-top: 30px;
}

.tile {
  width: 33%;
  float: left;
  color: white !important;

  div {
    color: white !important;
  }

  a[data-testid='guideItemLink'] {
    border: 0.5px solid rgb(186 186 186 / 50%) !important;
  }
}

.sky {
  color: var(--sky);
}
